"use client";

import React, { useState } from "react";
import AntdModal from "@/components/common/Modal/AntdModal";
import { Button, Calendar, Row, Col, Select } from "antd";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import useAuth from "@/hooks/useAuth";
import * as PYRServices from "@/services/PYRServices";

const BookAppointment = ({
  showModal,
  setShowModal,
  investorDetails,
  onSuccess,
}) => {
  const { user } = useAuth();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedTime, setSelectedTime] = useState(null);

  const onDateChange = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
    setSelectedTime(null);
  };

  const onTimeChange = (time) => {
    setSelectedTime(time);
  };

  const disabledDate = (current) => {
    const today = dayjs().startOf("day");
    const sevenDaysLater = dayjs().add(7, "days").endOf("day");
    return current < today || current > sevenDaysLater;
  };

  const generateTimeSlots = () => {
    const slots = [];
    const format = "hh:mm A";

    const start = dayjs().set("hour", 10).set("minute", 0).set("second", 0);
    const end = dayjs().set("hour", 22).set("minute", 0).set("second", 0);

    let current = start;
    while (current.isBefore(end)) {
      slots.push(current.format(format));
      current = current.add(30, "minute");
    }

    return slots;
  };

  const isTimeSlotDisabled = (timeSlot) => {
    if (!selectedDate) return true;

    const [time, period] = timeSlot.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let hour = hours;
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    const selectedDateTime = dayjs(selectedDate)
      .set("hour", hour)
      .set("minute", minutes)
      .set("second", 0);

    const now = dayjs();

    return selectedDateTime.isBefore(now);
  };

  const submitAppointment = async (date, timeSlot) => {
    try {
      if (user?._id || investorDetails?._id) {
        const payload = {
          investor_id: user?._id || investorDetails?._id || "",
          date: dayjs(date).format("YYYY-MM-DD"),
          time: dayjs(timeSlot, "hh:mm A").format("HH:mm"),
        };
        const response = await PYRServices.addAppointment(payload);
        if (response?.status === "Success") {
          const day = dayjs(date).format("Do");
          const formattedDate = `${day} ${dayjs(date).format("MMMM")}`;
          toast.success(
            `Thank you for reaching out! Our team will contact you at ${formattedDate} ${timeSlot}. We look forward to speaking with you!`
          );
          setShowModal(false);
        }
      } else {
        toast.error("Investor id is missing");
      }
    } catch (error) {
      toast.error(error?.response?.message || "Something went wrong");
    }
  };

  const handleBookAppointment = () => {
    if (selectedDate && selectedTime) {
      submitAppointment(selectedDate, selectedTime);
      setSelectedDate(null);
      setSelectedTime(null);
    } else {
      toast.error(
        "Please choose a date and time slot to book your appointment."
      );
    }
  };

  const availableSlots = () =>
    generateTimeSlots()?.filter((time) => !isTimeSlotDisabled(time));

  const isSubmitDisabled =
    !selectedDate ||
    !selectedTime ||
    dayjs(selectedDate).isAfter(dayjs().add(7, "days"));

  return (
    <AntdModal
      showModal={showModal}
      handleCancelModal={() => {
        setShowModal(false);
      }}
      footer={[
        <Button key="cancel" onClick={() => setShowModal(false)}>
          Cancel
        </Button>,
        <Button
          disabled={isSubmitDisabled}
          key="book"
          type="primary"
          onClick={handleBookAppointment}
        >
          Submit
        </Button>,
      ]}
      modalTitle={null}
    >
      <h3>Choose Date</h3>
      <Calendar
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const month = value.month();
          const year = value.year();

          const monthOptions = [];
          for (let i = 0; i < 12; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i}>
                {dayjs().month(i).format("MMMM")}
              </Select.Option>
            );
          }

          const monthSelector = (
            <Select
              value={month}
              onChange={(newMonth) => {
                const newValue = value.clone().month(newMonth);
                onChange(newValue);
              }}
              dropdownMatchSelectWidth={false}
            >
              {monthOptions}
            </Select>
          );

          return <div style={{ padding: 8 }}>{monthSelector}</div>;
        }}
        fullscreen={false}
        onSelect={onDateChange}
        disabledDate={disabledDate}
      />
      <h3
        className="mb-2"
        style={
          availableSlots()?.length > 0
            ? {}
            : { justifyContent: "center", display: "flex" }
        }
      >
        {availableSlots()?.length > 0 ? "Available Slot" : "No available slot"}
      </h3>
      {availableSlots()?.length > 0 && (
        <Row
          className="timeSlotsContainer"
          style={{ maxHeight: "130px", overflowY: "auto" }}
        >
          {availableSlots()?.map((time) => (
            <Col span={8} key={time}>
              <Button
                type={selectedTime === time ? "primary" : "default"}
                onClick={() => onTimeChange(time)}
                style={{ marginBottom: "10px" }}
              >
                {time}
              </Button>
            </Col>
          ))}
        </Row>
      )}
    </AntdModal>
  );
};

export default BookAppointment;
