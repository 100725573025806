"use client";
import useAuth from "@/hooks/useAuth";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Input, Row, Checkbox } from "antd";
import { useSelector } from "react-redux";
import {
  getLabelValueOptions,
  generateMonthOptions,
  getKeyValueData,
} from "@/utils/helper";
import CustomMultiSelect from "@/components/common/CustomSelect/CustomMultiSelect";
import { addAdditionalInfo } from "@/services/PYRServices";
import toast from "react-hot-toast";
import useResponsive from "@/hooks/useResponsive";
import isEmpty from "lodash/isEmpty";
import { updateProfile } from "@/services/AuthServices";

const businessModelOptions = [
  {
    label: "FOCO",
    value: "FOCO",
  },
  {
    label: "FICO",
    value: "FICO",
  },
  {
    label: "FOFO",
    value: "FOFO",
  },
];
const businessAutomationOptions = [
  {
    label: "Mini",
    value: "mini",
  },
  {
    label: "Semi",
    value: "semi",
  },
  {
    label: "Full",
    value: "full",
  },
];
const involvementTypeOptions = [
  {
    label: "Full time",
    value: "full_time",
  },
  {
    label: "Part time",
    value: "part_time",
  },
  {
    label: "Partially",
    value: "partially",
  },
];
const PyrAdditioanlInfo = ({ handlePyrSuccess, pyrData, handleClose }) => {
  const { profile } = useAuth();
  //console.log("pyrDatapyrData", pyrData);
  const initialValues = {
    business_model: [],
    involvement_time: [],
    business_automation: [],
    job_type: [],
    investment_source: [],
    location: [],
    time_period: 0,
    master_franchise: false,
    professional_yearly_income: "",
    yearly_income: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const { isMobile, isMobileOrTablet } = useResponsive();

  const { dropdownData } = useSelector((state) => state?.misc);
  //console.log("dropdownData", dropdownData);

  useEffect(() => {
    if (!isEmpty(profile)) {
      console.log("ree-rederr");
      const updatedValues = {
        ...initialValues,
        ...profile,
        professional_yearly_income:
          profile?.professional_yearly_income?.toString() || "",
        yearly_income: profile?.yearly_income?.toString() || "",
      };
      setFormValues((prevValues) =>
        JSON.stringify(prevValues) !== JSON.stringify(updatedValues)
          ? updatedValues
          : prevValues
      );
    }
  }, [profile]);

  const handleSelectChange = (value, name) => {
    if (name === "job_type") {
      const isPresentNone = value.find((vl) => vl === "None");
      const jobVal = isPresentNone ? ["None"] : value;
      setFormValues({
        ...formValues,
        [name]: jobVal,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleCheckboxChange = (checkedValues, name) => {
    setFormValues({
      ...formValues,
      [name]: checkedValues,
    });
  };

  const getPayload = () => {
    let payload = {
      time_period:
        typeof formValues?.time_period === "string"
          ? formValues?.time_period * 1
          : formValues?.time_period * 1,
      business_model: formValues.business_model || [],
      involvement_time: formValues.involvement_time || [],
      business_automation: formValues.business_automation || [],
      job_type: formValues.job_type || [],
      investment_source: formValues.investment_source || [],
      location: formValues.location || [],
      industry: formValues.industry || [],
      yearly_income: formValues?.yearly_income * 1,
      master_franchise: false,
    };
    return payload;
  };

  const getProfilePayload = () => {
    let payload = {
      ...formValues,
      professional_industry: {
        name: formValues?.professional_industry?.label,
        _id: formValues?.professional_industry?.value,
      },
      businesscategoryname: getKeyValueData(formValues?.businesscategoryname),
      categoryname: getKeyValueData(formValues?.categoryname),
      subcategoryname: getKeyValueData(formValues?.subcategoryname),
      professional_yearly_income: formValues?.professional_yearly_income * 1,
      yearly_income: formValues?.yearly_income * 1,
      time_period:
        typeof formValues?.time_period === "string"
          ? formValues?.time_period * 1
          : formValues?.time_period * 1,
    };
    return payload;
  };

  const updateProfileData = async () => {
    let payload = getPayload();
    try {
      payload.id = pyrData?._id;
      const res = await addAdditionalInfo(payload);

      if (res?.status === "Success") {
        let profilePayload = getProfilePayload();
        console.log("profilePayloadprofilePayload", profilePayload);
        await updateProfile(profilePayload);
        handlePyrSuccess();
        toast.success("Additional updated successfully");
      }
    } catch (error) {
      console.log("errorerror11", error);
      const message =
        error?.data?.error?.err_message?.toString() ||
        "Something went wrong, please try again!";
      toast.error(message);
    }
  };

  const jobTypeOptions = useMemo(() => {
    return getLabelValueOptions(dropdownData?.jobType);
  }, [dropdownData?.jobType]);

  const investmentSourceOptions = useMemo(() => {
    return getLabelValueOptions(dropdownData?.investmentSource);
  }, [dropdownData?.investmentSource]);

  const locationOptions = useMemo(() => {
    return getLabelValueOptions(dropdownData?.investorLocation);
  }, [dropdownData?.investorLocation]);

  const timePeriodMOnths = useMemo(() => {
    if (generateMonthOptions()?.length > 0) {
      return [...generateMonthOptions()];
    }
  }, [generateMonthOptions()]);

  return (
    <div
      className="w-full border-t border-primary-border-color pt-4 lg:p-4"
      id="interest_in_business"
    >
      <div className={`"cursor-not-allowed opacity-80" `}>
        <Row className="mt-3 lg:mb-8" gutter={16}>
          <Col span={isMobile ? 24 : 8} className="mb-3 lg:mb-0">
            <label className="mb-2 block w-full text-sm font-medium text-color-black lg:inline-block lg:font-normal lg:text-color-secondary-dark">
              Business Model
            </label>
            <Checkbox.Group
              options={businessModelOptions}
              value={formValues?.business_model}
              onChange={(checkedValues) =>
                handleCheckboxChange(checkedValues, "business_model")
              }
            />
          </Col>
          <Col span={isMobile ? 24 : 8} className="mb-3 lg:mb-0">
            <label className="mb-2 block w-full text-sm font-medium text-color-black lg:inline-block lg:font-normal lg:text-color-secondary-dark">
              Business Automation
            </label>
            <Checkbox.Group
              options={businessAutomationOptions}
              value={formValues?.business_automation?.map((vl) =>
                vl.toLowerCase()
              )}
              onChange={(checkedValues) =>
                handleCheckboxChange(checkedValues, "business_automation")
              }
            />
          </Col>
          <Col span={isMobile ? 24 : 8} className="mb-3 lg:mb-0">
            <label className="mb-2 block w-full text-sm font-medium text-color-black lg:inline-block lg:font-normal lg:text-color-secondary-dark">
              Involvement Type
            </label>
            <Checkbox.Group
              options={involvementTypeOptions}
              // value={formValues?.involvement_time}
              value={formValues?.involvement_time?.map((item) =>
                item.toLowerCase().replace(/\s+/g, "_")
              )}
              onChange={(checkedValues) =>
                handleCheckboxChange(checkedValues, "involvement_time")
              }
            />
          </Col>
        </Row>
        <Row className="lg:mb-8 lg:mt-3" gutter={16}>
          <Col span={isMobile ? 24 : 8} className="mb-2 lg:mb-0">
            <label className="mb-2 inline-block text-sm font-normal text-color-secondary-dark">
              Job Type
            </label>
            <CustomMultiSelect
              mode="multiple"
              className="block"
              placeholder="Select Job Type"
              size="large"
              options={jobTypeOptions}
              onChange={(value) => handleSelectChange(value, "job_type")}
              showSearch={true}
              labelInValue={false}
              value={formValues.job_type || undefined}
            />
          </Col>
          <Col span={isMobile ? 24 : 8} className="mb-2 lg:mb-0">
            <label className="mb-2 inline-block text-sm font-normal text-color-secondary-dark">
              Investment Source
            </label>
            <CustomMultiSelect
              mode="multiple"
              className="block"
              placeholder="Select Investment Source"
              size="large"
              options={investmentSourceOptions}
              onChange={(value) =>
                handleSelectChange(value, "investment_source")
              }
              showSearch={true}
              labelInValue={false}
              value={formValues.investment_source || undefined}
            />
          </Col>
          <Col span={isMobile ? 24 : 8} className="mb-2 lg:mb-0">
            <label className="mb-2 inline-block text-sm font-normal text-color-secondary-dark">
              Property Type
            </label>
            <CustomMultiSelect
              mode="multiple"
              className="block"
              placeholder="Select Location"
              size="large"
              options={locationOptions}
              onChange={(value) => handleSelectChange(value, "location")}
              showSearch={true}
              labelInValue={false}
              value={formValues.location || undefined}
            />
          </Col>
        </Row>
        <Row className="mb-8 lg:mt-3" gutter={16}>
          <Col span={isMobile ? 24 : 8}>
            <label className="mb-2 inline-block text-sm font-normal text-color-secondary-dark">
              Franchise Startup Timeline
            </label>
            <CustomMultiSelect
              mode="unset"
              className="block"
              placeholder="SelectTime period"
              size="large"
              options={timePeriodMOnths}
              onChange={(value) => handleSelectChange(value, "time_period")}
              showSearch={true}
              labelInValue={false}
              value={formValues.time_period || undefined}
            />
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <label className="mb-2 inline-block text-sm font-normal text-color-secondary-dark">
              Yearly Income
            </label>
            <div
              className="ant-form-item"
              style={{
                marginBottom: "24px",
              }}
            >
              <Input
                size="large"
                placeholder="Enter Yearly Income"
                value={formValues.yearly_income}
                type="number"
                onChange={(e) => {
                  handleSelectChange(e.target.value, "yearly_income");
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <>
        <Button
          type="primary"
          size="large"
          className=""
          onClick={updateProfileData}
        >
          Save
        </Button>
        <Button
          type="default"
          size="large"
          className="ml-5"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </>
    </div>
  );
};

export default PyrAdditioanlInfo;
